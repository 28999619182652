<template>
  <div></div>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "Logout",

  created() {
    Api.deleteCookie("user_token");
    Api.redirectToLogin();
  }
};
</script>
